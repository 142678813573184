import React from 'react';
import { GoogleLogin } from '@react-oauth/google';

function Introduction() {
  return (
    <div style={{textAlign: 'left'}}>
      <h1>We want you to break in</h1>
      <p>This is an incredibly simple site with a protected resource. Call it a Capture the Flag or CTF.</p>
      <p>The person who threw this site together did so in a hurry and on a budget, leaving the site vulnerable.</p>
      <p>Your goal is to exploit the vulnerable security methods you find to reach data you should not have access to and exfill the flag.</p>
      <p>In the flag, you will find instructions for how and where to submit your work so that you can setup an interview and be considered for a position.</p>
      <p>Flags are unique to each applicant. Do keep in mind that discovering an applicant copied another applicant's flag is a trivial task. Submit anything that is not your own work at your own risk.</p>
      <p>The scope of the malicious activities that you are explicitly permitted to commit are restricted to this site and this site alone.</p>
      <p>With that out of the way...</p>

      <h1>Things you will need</h1>
      <ul>
        <li>
          <p>A Google Account. We suggest using a burner account. You are able to generate at least 4 Google Accounts with the same phone number. We suggest keeping your first name the same but make sure the rest of the data for your burner is meaningless. In any case, the data being extracted from any account that signs in are what Google considers non-sensitive.</p>
        </li>
        <li>
          <p><a href='https://www.kali.org/get-kali/#kali-virtual-machines'>Kali</a>. You could of course manually configure any Linux distro (potentially even Windows or MacOS) with the specific tools you'll need but you will have a much easier time using a distro that comes bundled with all of the necessary resources and works smoothly in a VM. We recommend virtualizing Kali (and any other pwnbox for that matter) using VirtualBox rather than installing on bare metal. Instructions for how to do this can be found <a href='https://www.kali.org/docs/virtualization/import-premade-virtualbox/'>here.</a></p>
          <p>Use this VM to interact with the CTF challenge; it's just good digital hygiene. (NOTE: Some configurations of Firefox ESR that come with various Linux distros are incompatible with Google Sign In. This is a constantly evolving issue. If you experience problems, install and try a new browser on your pwnbox when entering the ctf. If you must use a different browser than what your distro came with, be prepared to update whichever pen testing tools you need that are configured to use the incompitable browser.)</p>
        </li>
      </ul>
      <h1>General Tips</h1>
      <ul>
        <li>
          <p>Pay attention to what's happening behind the scenes.</p>
        </li>
        <li>
          <p>Identify <i>Who</i> is <i>Authorizing</i> <i>What</i> and <i>How</i>. Don't neglect <i>Where</i> and <i>When</i>.</p>
        </li>
        <li>
          <p>Research. Use every internet resource at your disposal to find documentation on the security methods being used and how to exploit them. Turn ChatGPT into DAN (Do Anything Now) if you have to or find blogs on exploit techniques.</p>
        </li>
      </ul>
    </div>
  )
}

export default Introduction;