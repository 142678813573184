import React, { useEffect, useState } from 'react';
import './App.css';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Introduction from './pageContent/Introduction.js';
import GetTheFlag from './pageContent/GetTheFlag.js';

function App() {

  // Oh hello there! I like where your head is at. We make fun of people who 'press F12 to hack' but it is a good place to start; reconnaissance is an indispensable step you will keep returning too.
  // Also, it is shocking what developers will sometimes leave in their code.
  // In your case however, you are going to have to dig deeper to find the flag.

  const [userData, setUserData] = useState('');

  useEffect(() => {
    console.log("You see all the noise in here from Google? You can filter that out; it isn't pertinent to the CTF challenge and only appears because the verification with Google for this web app is still in process. Happy hunting!")
    const storedUserData = sessionStorage.getItem('UserData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, [])

  const handleLoginFailure = (response) => {
    console.log(response);
  }

  const handleLoginSuccess = async (response) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Zeus`, {
        tokenId: response.credential
      });
      const decodedToken = jwtDecode(result.data);
      const jsonFriendly = JSON.stringify(decodedToken);
      sessionStorage.setItem('jwtToken', result.data);
      sessionStorage.setItem('UserData', jsonFriendly);
      setUserData(JSON.parse(jsonFriendly));
    } catch (error) {
      console.log(error);
    }
  }

  const logOut = () => {
    googleLogout();
    sessionStorage.removeItem('jwtToken');
    sessionStorage.removeItem('UserData');
    setUserData('');
  }

  return (
    <div className="App" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '20px auto',
      maxWidth: '1200px',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  }}>
      {userData ? (
          <>
              <h2>Welcome {userData.first_name}</h2>
              <button onClick={logOut} style={{ width: '100px', marginTop: '10px' }}>Logout</button>
              <br />
              <GetTheFlag />
          </>
      ) : (
          <>
              <Introduction />
              <h2>Log into a Google Account to begin</h2>
              <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} text='continue_with'/>
          </>
      )}
  </div>
  );
}
export default App;