import React, { useEffect, useState } from 'react';
import axios from 'axios';

function GetTheFlag() {
  const [content, setContent] = useState('');

  const callTheBackend = async () => {
    const jwtToken = sessionStorage.getItem('jwtToken');
    try {
      const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Rock`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      });
      setContent(result.data);
    } catch (error) {
      setContent(error.response.data);
    }
   }

  return (
    <>
      <button onClick={() => callTheBackend()} style={{display: 'block', margin: '20px auto' }}>Get The Flag</button>
      <br />
      <div style ={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {/* Yup, if you got excited when you saw dangerouslySetInnerHTML, you're in the right headspace. Unfortunately, XSS attacks are not what will be getting you the flag and all user inputs are sanitized. Get your dirty javascript outta here; we live in a society */}
        <div dangerouslySetInnerHTML={{__html: content}} style={{
          textAlign: 'left',
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '20px',
          lineHeight: '1.6',
          fontSize: '18px',
          backgroundColor: '#f9f9f9',
          borderRadius: '8px',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        }}>
        </div>
      </div>
    </>
  )
}
export default GetTheFlag;